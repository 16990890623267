import React from 'react';

import { AddBusiness, ChevronRight, Store } from '@mui/icons-material';
import { Avatar, Button, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
import { isEmpty, map } from 'lodash';
import { Link } from 'react-router-dom';
import { useTrackPageView } from '../../components';
import { AppContext } from '../../contexts/app_context';
import Protector from '../../components/protector';

import type { RestaurantType } from '../../contexts/app_context';

export const RestaurantsPage = () => {
  const { contextRestaurants, setContextCurrentRestaurant } = React.useContext(AppContext);

  useTrackPageView('RestaurantPage');

  const restaurantCardClickHandlerHigherOrder = (restaurant: RestaurantType) => () => {
    setContextCurrentRestaurant(restaurant);
  };

  if (isEmpty(contextRestaurants)) {
    return (
      <Container
        maxWidth={'sm'}
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Protector permissions={['restaurant_create']}>
          <Protector.Allowed>
            <Button component={Link} to="new" variant="outlined" startIcon={<AddBusiness />}>
              เพิ่มสาขา
            </Button>
          </Protector.Allowed>
          <Protector.Rejected>ไม่สามารถเพิ่มสาขาได้</Protector.Rejected>
        </Protector>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <Grid container spacing={1}>
        {map(contextRestaurants, (restaurant) => (
          <Grid key={restaurant.id} item xs={12} sm={6}>
            <Stack>
              <Card
                component={Link}
                to={`${restaurant.id}`}
                onClick={restaurantCardClickHandlerHigherOrder(restaurant)}
                style={{ textDecoration: 'none' }}
              >
                <CardContent>
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <Avatar src={restaurant.frontPhotoUri} sizes="large">
                      <Store />
                    </Avatar>

                    <Stack sx={{}}>
                      <Typography variant="caption">{restaurant.name}</Typography>
                      <Typography>{restaurant.branchName}</Typography>
                    </Stack>

                    <div style={{ flex: 1 }} />
                    <ChevronRight />
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Protector permissions={['restaurant_create']}>
        <Protector.Allowed>
          <Button
            component={Link}
            to="new"
            variant="outlined"
            fullWidth
            startIcon={<AddBusiness />}
            sx={{ marginTop: 3 }}
          >
            เพิ่มสาขา
          </Button>
        </Protector.Allowed>
      </Protector>
    </Container>
  );
};
